import React from 'react'
import rig from '../../assets/right.png'
import lef from '../../assets/left.png'
import  backim from '../../assets/backtext.png'
import point from '../../assets/point.png'




import phone from "../../assets/phone.png";
import user from "../../assets/user.png";
import web from "../../assets/web.png";

import './second.css'
function second() {
  return (
    <div className="secf">
    <img src={lef} className="left" />
    <div className="toppoint">
      <img src={point} />
    </div>
    <div className="containerf">
      <div className="backimg">
        <img src={backim} />
      </div>

      <div className="info">
        <h1>إدارة التأمينات  العامة</h1>
        <div>
          <div className="numinfo">
            <img src={phone} />
            <h3 className="num">0912203658</h3>
          </div>

          <div className="nameinfo">
            <img src={user} />
            <h3 className="name">أحمد العباني</h3>
          </div>
        </div>

        <div className="mailinfo">
          <img src={web} />
          <h3 className="mail">a.alabani@trustgroup.com.ly</h3>
        </div>


      
      </div>
    </div>
  </div>
  )
}

export default second