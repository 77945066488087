
import logo from '../../assets/brand.png'
import top from '../../assets/topshape.png'
import brand from '../../assets/logo.png'
import bg from '../../assets/headerbg.png'
import '../../index.css'
import './header.css';
import React from 'react'
import {Animated} from "react-animated-css";

export default function header() {
  return (

      <div className='he'>
      <img src={bg}  className='hdbg'/>
      <nav className='navbar'>

        <div className='container'>
        <img src={brand} className='brand'></img>  
        <img src={logo}  className='logo'/>
        </div>
      </nav>

        <section className='shapetop'>
            <img src={top} className='topshape'/>
        </section>
        <Animated animationIn="zoomIn" animationOut="slideOutUp" animationInDuration={1800} animationOutDuration={1800} isVisible={true}>

        <section className='texthd'>

            <div>
            <h1>TRUST</h1>
            <h1 className='sec'>INSURANCE</h1>
            <p>


            تأسست شركة الثقة للتأمين (  ش م ل ) ، يوم <span> /1374/11/19 </span> و.ر الموافق<span> 19 / 11/ 2006م</span>. بموجب الإذن بتأسيسها وفق قرار رقم ( 2 ) لسنة 1374 و.ر الموافق 2006 م الصادر عن أمانة اللجنة الشعبية العامة للاقتصاد والتجارة والاستثمار, يساهم فيها ليبيون بنسبة %51 , وشركاء أجانب بنسبة 49% من رأسمالها  
            
            <br></br>
            <br></br>

            إن شركة الثقة للتأمين ستكون إضافة نوعية في سوق التأمين الليبي نظراً لما يتوفر لها من خبرات ليبية وعربية وعالمية تتجاوز ثلاثة عقود في مجال تقديم الخدمات التأمينية.
            <br></br>
            <br></br>

            إن كل ذلك سيؤهلها لتكون خدماتها منافسة ومتميزة وتغطياتها التأمينية أشمل ، حيث إنها تقوم بدور الاستشاري لزبائنها وحملة وثائقها، بإعادة دراسة وثائق التأمين وشروطها وإستثناءاتها من وقت لآخر وتقديم المشورة الفنية لزبائنها لإدخال  التعديلات المطلوبةعلى تلك الوثائق بما يلبي حاجة حملة الوثائق التأمينية.
            <br></br>
            <br></br>
            إن تسوية التعويضات تحضى بالأولوية القصوى لدى المسؤولين والعاملين في شركة الثقة للتأمين ، وذلك هو سر نجاح وتميز خبرائها الفنيين والاداريين في السابق.
            <br></br>
            <br></br>
            إن شركة الثقة للتأمين وهي  تتطلع الى أن تقدم لكم خدماتها التأمينية ، الواثقة بأنها ستفوز بثقتكم التي تمثل لدينا هدفاً مرجوا،ً وختاماً نرجو أن تتقبلوا أطيب الأمنيات بدوام الصحة والعافية ، ولمؤسستكم التقدم والازدهار.
            <br></br>
            <br></br>
         

            </p>
            </div>
        </section>
        </Animated>

      </div>
    )
}
