import Header from '../src/components/header/header'
 import First from '../src/components/first/first';
import Second from '../src/components/second/second'
import Tihrd from '../src/components/thired/thired'
import Four from '../src/components/four/four'

import Fotter from '../src/components/footer/foter'

function App() {
  return (
    <>

    <div>
    <Header />
    <First />
    <Second />
    <Tihrd />
    <Four />
    <Fotter />
    </div>

    </>
    
  );
}

export default App;
