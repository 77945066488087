import React from "react";
import insta from "../../assets/instagram.png";
import twit from "../../assets/twitter.png";
import logo from "../../assets/logof.png";
import face from "../../assets/facebook.png"
import {  FaEnvelope , FaInstagram , FaFacebookF , FaTwitter , FaPhoneSquareAlt} from 'react-icons/fa';
import "./foter.css";

function foter() {
  let instlink = "https://www.instagram.com/trustgroup_ly/?hl=en"
  let twitlink = "https://twitter.com/trustgrouply_ly"
  let facelink = "https://www.facebook.com/Trustinsurance2/"
  return (
    <div className="cont">
      <div className="fotter">

        <div className="social">


          <div className="tw">
      
            <h4>
            <FaTwitter  className="fa-icona" style={{  color:'#F17614'   }}></FaTwitter>
            <a href={twitlink}> trustgrouply_ly </a>            
            </h4>
          </div>


          <div className="ins">
            
              <h4>
              <FaInstagram  className="fa-icona"style={{  color:'#F17614'  }}></FaInstagram>
              <a href={instlink}>{''}trustgroup_ly</a>
              </h4>
            </div>

          <div className="fac">
            <h4>
            <FaFacebookF  className="fa-icona" style={{  color:'#F17614'  }}></FaFacebookF>

            <a href={facelink}>Trust Insurance Co</a>
            </h4>
          </div>

          <div className="ins">
        
            <h4>
            <FaEnvelope  style={{  color:'#F17614'  }}/>
            <a href={'#'}> info@trustgroup.com.ly</a>
            </h4>
          </div>

          <div className="ins">
 
            <h4>
            <FaEnvelope className="fa-icona" style={{  color:'#F17614'  }}/>
            <a href={'#'} style={{  marginRight:'5'  }} >trustinsurance@trustgroup.com.ly</a>
            </h4>
          </div>



        </div>
        <div className='nums'>


        <div >
    <h4><FaPhoneSquareAlt className="fa-icona" style={{  color:'#F17614' , marginRight:'5'  }}/>0213621661</h4>
    <h4><FaPhoneSquareAlt className="fa-icona" style={{  color:'#F17614'  , marginRight:'5'   }}/>0213621662</h4>
    <h4><FaPhoneSquareAlt className="fa-icona" style={{  color:'#F17614'  , marginRight:'5'   }} />0213621663</h4>


    </div>  
    <div >

    <h4><FaPhoneSquareAlt className="fa-icona" style={{  color:'#F17614'  , marginRight:'5'   }}/>0213621664</h4>
    <h4><FaPhoneSquareAlt className="fa-icona" style={{  color:'#F17614'  , marginRight:'5'   }}/>0213621665</h4>

    </div>  
        </div>



      <div className="logor">
        <img src={logo} />
      </div>

      </div>
    </div>
  );
}

export default foter;
