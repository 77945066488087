import React from 'react'

import rig from '../../assets/right.png'
import lef from '../../assets/left.png'
import  backim from '../../assets/backtext.png'
import point from '../../assets/point.png'



import phone from "../../assets/phone.png";
import user from "../../assets/user.png";
import web from "../../assets/web.png";

import './four.css'
function four() {
  return (
    <div className="secf">
    <div className="toppoint">
      <img src={point} />
    </div>
    <div className="containerf">
      <div className="backimg">
        <img src={backim} />
      </div>
      <div className="info">
        <h1>إدارة تأمين الطيران</h1>
        <div>
          <div className="numinfo">
            <img src={phone} />
            <h3 className="num">0912206866</h3>
          </div>

          <div className="nameinfo">
            <img src={user} />
            <h3 className="name">فوزي المجذوب</h3>
          </div>
        </div>

        <div className="mailinfo">
          <img src={web} />
          <h3 className="mail">felmajdoub@trustgroup.com.ly</h3>
        </div>


      
      </div>
    </div>
  </div>
  )
}

export default four