import React from "react";
import rig from "../../assets/right.png";
import lef from "../../assets/left.png";
import backim from "../../assets/backtext.png";
import point from "../../assets/point.png";
import phone from "../../assets/phone.png";
import user from "../../assets/user.png";
import web from "../../assets/web.png";
import {Animated} from "react-animated-css";
//css
import "./firs.css";
function first() {
  return (




    <div className="secf">


  
    
      <img src={lef} className="left" />
      <div className="toppoint">
        <img src={point} />
      </div>
      <div className="containerf">
        <div className="backimg">
          <img src={backim} />
        </div>
        <Animated animationIn="zoomIn" animationOut="slideOutUp" animationInDuration={3000} animationOutDuration={1800} isVisible={true}>

        <div className="info">
          <h1>إدارة التأمين الحياة والصحة</h1>
          <div>
            <div className="numinfo">
              <img src={phone} />
              <h3 className="num">0912205327</h3>
            </div>

            <div className="nameinfo">
              <img src={user} />
              <h3 className="name">عماد بريش</h3>
            </div>
          </div>

          <div className="mailinfo">
            <img src={web} />
            <h3 className="mail">emad.med@trustgroup.com.ly</h3>
          </div>

          <h4>_______________________________</h4>

          <div>
            <div className="numinfo">
              <img src={phone} />
              <h3 className="num">0912205463</h3>
            </div>

            <div className="nameinfo">
              <img src={user} />
              <h3 className="name">هشام كارة</h3>
            </div>
          </div>
          <div className="mailinfo">
            <img src={web} />
            <h3 className="mail">hesham.med@trustgroup.com.ly</h3>
          </div>
        </div>
        </Animated>

      </div>
      </div>
   
  );
}

export default first;
